<script>
import {mapMutations, mapGetters} from 'vuex'
export default {
	props: ['picture', 'price', 'title', 'description', 'type', 'id'],
	computed: {
		item() {
			let {id, price, title, description, picture} = this
			return {
				id,
				title,
				description,
				price,
				picture
			}
		},
		...mapGetters({
			qtyCart: 'qtyCart'
		})
	},
	methods: {
		...mapMutations({
			addToCart: 'addToCart',
			updateCart: 'updateCart',
			removeToCart: 'removeToCart',
		})
	}
}
</script>

<template>
	<div  class="hotdog" :class="{'hotdog--in-cart': qtyCart(id, type)}">
		<template v-if="['hotdogs'].includes(type)">
			<div class="hotdog__top">
				<img class="hotdog__picture" :src="picture" alt="">
				<p class="hotdog__price">{{price | numeral('$0,0.00') }}</p>
			</div>
			<div class="hotdog__content">
				<h2 class="hotdog__title">{{title}}</h2>
				<p v-if="description" class="hotdog__description">{{description}}</p>
			</div>
		</template>
		<template v-else>
			<div v-if="picture"  class="hotdog__top">
				<img class="hotdog__picture" :class="{'hotdog__picture--contain': type == 'drinks'}" :src="picture" alt="">
			</div>
			<div class="hotdog__content">
				<div class="hotdog__only-price">
					+ {{price | numeral('$0,0.00') }}
				</div>
				<h2 class="hotdog__only-title">{{title}}</h2>
			</div>
		
		</template>
		<div class="hotdog__footer">
			<div class="hotdog__control">
				<button class="hotdog__control-button" @click="removeToCart({id, type})">
					<span class="icon-down"></span>
				</button>
				<input class="hotdog__control-input" type="text" :value="qtyCart(id, type)" 
					@change="(event) => (
						updateCart({
						item, 
						qty: Number(event.target.value),
						type,
					}))">
				<button class="hotdog__control-button" @click="addToCart({item, type})">
					<span class="icon-up"></span>
				</button>
			</div>
		</div>
	</div>
</template>